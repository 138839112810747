export const environment = {
    production: true,
    apiURL: 'https://api-console.crizac.com/',
    fileBaseUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system',
    callBaseUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/yeasterdownload/',
    zegoCloud: 'https://connect.crizac.com/',
    apiPHPURL: 'https://accounts.crizac.com/',
    apiPHPURLv2: 'https://accounts.crizac.com/api_mongo/',
    fileDownloadUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com',
    oneSignalAppId: 'bdf3c133-134f-4973-8414-01085056b1b7',
    chatBaseUrl: "wss://api.crizac.com/support-chat-notification",
    transportBaseURL: "https://api-prod.crizac.com/",
    agentPortallink:'https://portal.crizac.com/',
    agentPortallinkUC:'https://portal.ucol.co.uk/',
    agentPortallinkGA:'https://portal.gasolutions.in/',
    predictorApiKey:'1ad11872-0537-4406-b01e-de5c52995492',
    predictorApiUrl: 'https://eligibility-predictor.crizac.com',
    fileProfileUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/users/profiles/',
    sopURL:'https://sop-generator.crizac.com/',
    fileUsersUrl: 'https://crizac-assets.s3.ap-south-1.amazonaws.com/system',
    chatURL: "https://api.crizac.com/",
};
